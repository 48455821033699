@mixin newuser{

    #newuser{
        display: flex; width: 50rem; height: 20rem; justify-content: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: .2rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }
    #newusermobile{
        display: flex; width: 90vw; height: 40vh; justify-content: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: .2rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }



}