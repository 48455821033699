@mixin login{

    #login{
        width: 40vw;
        height: 65vh;
        border-radius: .3rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: map-get($map: $color, $key: color-600 );
        font-weight: 100;
        padding: 6rem .5rem .5rem .5rem;
    }

    #loginMobile{
        width: 90vw;
        height: 30vh;
        border-radius: .3rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: map-get($map: $color, $key: color-600 );
        font-weight: 100;
        padding: 1rem .5rem .5rem .5rem;
    }
}