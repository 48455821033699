@mixin card{

    #card{
        border-radius: .5rem;
        background-color: rgba(255, 255, 255, .8);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        font-family: 'Raleway', sans-serif;
        text-align: center;
    }

}