@mixin photo{

    #photo{
        display: flex; width: 4rem; height: 3rem;
        margin: 0rem 1rem 10rem 0rem;
        background-image: url(../ASSETS/photo.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #photo:hover{
        background-image: url(../ASSETS/photohover.png); 
        transition: .5s;
    }

}