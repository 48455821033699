@mixin usercard{

    #usercard{
        display: flex; width: 70rem; height: 8rem;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #usercardmobile{
        display: flex; width: 90vw; height: 20vh;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }



}