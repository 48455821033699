@mixin newemail{

    #newemail{
        display: flex; width: 50rem; height: 26rem; justify-content: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: .2rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #newemailmobile{
        display: flex; width: 86vw; height: 28rem; justify-content: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: .2rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }



}