@mixin upload{

    #upload{
        display: flex; width: 4rem; height: 3rem;
        margin: 0rem 1rem 10rem 0rem;
        background-image: url(../ASSETS/upload.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #upload:hover{
        background-image: url(../ASSETS/uploadhover.png); 
        transition: .5s;
    }

    #uploadNone{
        display: none;
    }

}