.cont {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin: 0rem 0rem 0rem 0rem;
  overflow: hidden;
}

.justify-left {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-right {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-space {
  justify-content: space-between;
}

.align-top {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-bottom {
  align-items: flex-end;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-ynone {
  overflow-y: hidden;
}

.overflow-xnone {
  overflow-x: hidden;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.z-index-100 {
  z-index: 100;
}

.font-50 {
  font-size: calc(0.5vw + 0.5rem);
}

.font-100 {
  font-size: calc(0.6vw + 0.6rem);
}

.font-150 {
  font-size: calc(0.6vw + 0.5rem);
}

.font-200 {
  font-size: calc(0.7vw + 0.7rem);
}

.font-300 {
  font-size: calc(1vw + 1rem);
}

.font-400 {
  font-size: calc(1.3vw + 1.3rem);
}

.font-500 {
  font-size: calc(1.5vw + 1.5rem);
}

.font-600 {
  font-size: calc(1.7vw + 1.7rem);
}

.font-700 {
  font-size: calc(1.9vw + 1.9rem);
}

.font-800 {
  font-size: calc(2.1vw + 2.1rem);
}

.font-900 {
  font-size: calc(2.3vw + 2.3rem);
}

.weight-100 {
  font-weight: 100;
}

.weight-200 {
  font-weight: 200;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}

.weight-900 {
  font-weight: 900;
}

.color-50 {
  color: #e3eaf2;
}

.color-100 {
  color: #c8d6e4;
}

.color-200 {
  color: #bacbde;
}

.color-300 {
  color: #9fb7d1;
}

.color-400 {
  color: #83a2c3;
}

.color-500 {
  color: #5a83af;
}

.color-600 {
  color: #42658a;
}

.color-700 {
  color: #35516e;
}

.color-800 {
  color: #283c53;
}

.color-900 {
  color: #1b2837;
}

.color-red {
  color: red;
}

.color-baseWhite {
  background-color: rgba(255, 255, 255, 0.7);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

#back {
  background-image: url(../ASSETS/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#backMobile {
  display: flex;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background-image: url(../ASSETS/backm.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#backImage {
  background-image: url(../ASSETS/backImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-35 {
  width: 35%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-60 {
  width: 60%;
}

.width-65 {
  width: 65%;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}

.width-80 {
  width: 80%;
}

.width-85 {
  width: 85%;
}

.width-90 {
  width: 90%;
}

.width-95 {
  width: 95%;
}

.width-100 {
  width: 100%;
}

.height-5 {
  height: 5%;
}

.height-7 {
  height: 7%;
}

.height-10 {
  height: 10%;
}

.height-15 {
  height: 15%;
}

.height-20 {
  height: 20%;
}

.height-25 {
  height: 25%;
}

.height-30 {
  height: 30%;
}

.height-35 {
  height: 35%;
}

.height-40 {
  height: 40%;
}

.height-45 {
  height: 45%;
}

.height-50 {
  height: 50%;
}

.height-55 {
  height: 55%;
}

.height-60 {
  height: 60%;
}

.height-65 {
  height: 65%;
}

.height-70 {
  height: 70%;
}

.height-75 {
  height: 75%;
}

.height-80 {
  height: 80%;
}

.height-85 {
  height: 85%;
}

.height-90 {
  height: 90%;
}

.height-95 {
  height: 95%;
}

.height-100 {
  height: 100%;
}

.padding-0 {
  padding: 0px 0px 0px 0px;
}

.padding-left-1 {
  padding-left: 1%;
}

.padding-left-2 {
  padding-left: 2%;
}

.padding-left-3 {
  padding-left: 3%;
}

.padding-left-4 {
  padding-left: 4%;
}

.padding-left-5 {
  padding-left: 5%;
}

.padding-left-10 {
  padding-left: 10%;
}

.padding-left-15 {
  padding-left: 15%;
}

.padding-left-20 {
  padding-left: 20%;
}

.padding-left-25 {
  padding-left: 25%;
}

.padding-left-30 {
  padding-left: 30%;
}

.padding-right-1 {
  padding-right: 1%;
}

.padding-right-2 {
  padding-right: 2%;
}

.padding-right-3 {
  padding-right: 3%;
}

.padding-right-4 {
  padding-right: 4%;
}

.padding-right-5 {
  padding-right: 5%;
}

.padding-right-10 {
  padding-right: 10%;
}

.padding-right-15 {
  padding-right: 15%;
}

.padding-right-20 {
  padding-right: 20%;
}

.padding-right-25 {
  padding-right: 25%;
}

.padding-right-30 {
  padding-right: 30%;
}

.padding-top-1 {
  padding-top: 1%;
}

.padding-top-2 {
  padding-top: 2%;
}

.padding-top-3 {
  padding-top: 3%;
}

.padding-top-4 {
  padding-top: 4%;
}

.padding-top-5 {
  padding-top: 5%;
}

.padding-top-10 {
  padding-top: 10%;
}

.padding-top-15 {
  padding-top: 15%;
}

.padding-top-20 {
  padding-top: 20%;
}

.padding-top-25 {
  padding-top: 25%;
}

.padding-top-30 {
  padding-top: 30%;
}

.padding-top-30 {
  padding-top: 35%;
}

.padding-bottom-1 {
  padding-bottom: 1%;
}

.padding-bottom-2 {
  padding-bottom: 2%;
}

.padding-bottom-3 {
  padding-bottom: 3%;
}

.padding-bottom-4 {
  padding-bottom: 4%;
}

.padding-bottom-5 {
  padding-bottom: 5%;
}

.padding-bottom-10 {
  padding-bottom: 10%;
}

.padding-bottom-15 {
  padding-bottom: 15%;
}

.padding-bottom-20 {
  padding-bottom: 20%;
}

.padding-bottom-25 {
  padding-bottom: 25%;
}

.padding-bottom-30 {
  padding-bottom: 30%;
}

.margin-left-1 {
  margin-left: 1%;
}

.margin-left-2 {
  margin-left: 2%;
}

.margin-left-3 {
  margin-left: 3%;
}

.margin-left-4 {
  margin-left: 4%;
}

.margin-left-5 {
  margin-left: 5%;
}

.margin-left-10 {
  margin-left: 10%;
}

.margin-left-15 {
  margin-left: 15%;
}

.margin-left-20 {
  margin-left: 20%;
}

.margin-left-25 {
  margin-left: 25%;
}

.margin-left-30 {
  margin-left: 30%;
}

.margin-left-50 {
  margin-left: 50%;
}

.margin-left-60 {
  margin-left: 60%;
}

.margin-left-70 {
  margin-left: 70%;
}

.margin-left-80 {
  margin-left: 80%;
}

.margin-right-1 {
  margin-right: 1%;
}

.margin-right-2 {
  margin-right: 2%;
}

.margin-right-3 {
  margin-right: 3%;
}

.margin-right-4 {
  margin-right: 4%;
}

.margin-right-5 {
  margin-right: 5%;
}

.margin-right-10 {
  margin-right: 10%;
}

.margin-right-15 {
  margin-right: 15%;
}

.margin-right-20 {
  margin-right: 20%;
}

.margin-right-25 {
  margin-right: 25%;
}

.margin-right-30 {
  margin-right: 30%;
}

.margin-top-1 {
  margin-top: 1%;
}

.margin-top-2 {
  margin-top: 2%;
}

.margin-top-3 {
  margin-top: 3%;
}

.margin-top-4 {
  margin-top: 4%;
}

.margin-top-5 {
  margin-top: 5%;
}

.margin-top-10 {
  margin-top: 10%;
}

.margin-top-15 {
  margin-top: 15%;
}

.margin-top-20 {
  margin-top: 20%;
}

.margin-top-25 {
  margin-top: 25%;
}

.margin-top-30 {
  margin-top: 30%;
}

.margin-top-40 {
  margin-top: 40%;
}

.margin-top-50 {
  margin-top: 50%;
}

.margin-bottom-1 {
  margin-bottom: 1%;
}

.margin-bottom-2 {
  margin-bottom: 2%;
}

.margin-bottom-3 {
  margin-bottom: 3%;
}

.margin-bottom-4 {
  margin-bottom: 4%;
}

.margin-bottom-5 {
  margin-bottom: 5%;
}

.margin-bottom-10 {
  margin-bottom: 10%;
}

.margin-bottom-15 {
  margin-bottom: 15%;
}

.margin-bottom-20 {
  margin-bottom: 20%;
}

.margin-bottom-25 {
  margin-bottom: 25%;
}

.margin-bottom-30 {
  margin-bottom: 30%;
}

.margin-bottom-40 {
  margin-bottom: 40%;
}

.margin-bottom-50 {
  margin-bottom: 50%;
}

.border-up {
  border-top: solid 1px #35516e;
}

.border-down {
  border-bottom: solid 1px #35516e;
}

.border-start {
  border-left: solid 1px #35516e;
}

.border-end {
  border-right: solid 1px #35516e;
}

.testcolor {
  background-color: rgba(105, 138, 71, 0.5);
}

.white {
  background-color: rgba(255, 255, 255, 0.5);
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.label {
  display: flex;
  width: 80%;
  height: auto;
  font-weight: 100;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.input {
  display: flex;
  width: 70%;
  height: auto;
  font-weight: 100;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.3rem;
}

#inputNarrow {
  display: flex;
  width: 50%;
  height: auto;
  font-weight: 100;
  margin: 0.5rem 0.5rem 0.5rem 1.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.3rem;
}

.select {
  display: flex;
  width: 60%;
  height: 2.6rem;
  font-weight: 100;
  margin: 0rem 0rem 0rem 0rem;
  padding: 0rem 0rem 0rem 0rem;
  border-radius: 0.3rem;
}

.button {
  box-shadow: inset 0px 1px 0px 0px #c8d6e4;
  background: linear-gradient(to bottom, #42658a 5%, #42658a 100%);
  background-color: #42658a;
  border: 1px solid #283c53;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-weight: 200;
  padding: 6px 12px;
  text-decoration: none;
}

.button:hover {
  background: linear-gradient(to bottom, #283c53 5%, #283c53 100%);
  background-color: #42658a;
}

.button:active {
  position: relative;
  top: 1px;
}

#arrowleft {
  display: flex;
  width: 3rem;
  height: 3rem;
  margin: 0rem 1rem 10rem 0rem;
  background-image: url(../ASSETS/arrowleft.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#arrowleft:hover {
  background-image: url(../ASSETS/arrowlefthover.png);
  transition: 0.5s;
}

#arrowNone {
  display: none;
}

#arrowleftMobile {
  display: flex;
  width: 4rem;
  height: 4rem;
  margin: 0rem 1rem 10rem 0rem;
  background-image: url(../ASSETS/arrowleft.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#arrowright {
  display: flex;
  width: 3rem;
  height: 3rem;
  margin: 0rem 0rem 10rem 1rem;
  background-image: url(../ASSETS/arrowright.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#arrowright:hover {
  background-image: url(../ASSETS/arrowrighthover.png);
  transition: 0.5s;
}

#arrowrightMobile {
  display: flex;
  width: 4rem;
  height: 4rem;
  margin: 0rem 0rem 10rem 1rem;
  background-image: url(../ASSETS/arrowright.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#card {
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-family: "Raleway", sans-serif;
  text-align: center;
}

#photo {
  display: flex;
  width: 4rem;
  height: 3rem;
  margin: 0rem 1rem 10rem 0rem;
  background-image: url(../ASSETS/photo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#photo:hover {
  background-image: url(../ASSETS/photohover.png);
  transition: 0.5s;
}

#upload {
  display: flex;
  width: 4rem;
  height: 3rem;
  margin: 0rem 1rem 10rem 0rem;
  background-image: url(../ASSETS/upload.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#upload:hover {
  background-image: url(../ASSETS/uploadhover.png);
  transition: 0.5s;
}

#uploadNone {
  display: none;
}

#pdf {
  display: flex;
  width: 2vw;
  height: 4vh;
  margin: 0rem 0rem 0rem 4rem;
  background-image: url(../ASSETS/pdf.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.4rem;
}

#login {
  width: 40vw;
  height: 65vh;
  border-radius: 0.3rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #42658a;
  font-weight: 100;
  padding: 6rem 0.5rem 0.5rem 0.5rem;
}

#loginMobile {
  width: 90vw;
  height: 30vh;
  border-radius: 0.3rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #42658a;
  font-weight: 100;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
}

#adminButton {
  display: flex;
  width: 20rem;
  height: 20rem;
  left: 5rem;
  top: 5rem;
  position: absolute;
  border-radius: 50%;
}

#adminButtonMobile {
  display: flex;
  width: 8rem;
  height: 8rem;
  left: 9rem;
  top: 2rem;
  position: absolute;
  border-radius: 50%;
}

#usercard {
  display: flex;
  width: 70rem;
  height: 8rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#usercardmobile {
  display: flex;
  width: 90vw;
  height: 20vh;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#search {
  display: flex;
  width: 50rem;
  height: 8rem;
  justify-content: center;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 2rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#searchmobile {
  display: flex;
  width: 90vw;
  height: 15vh;
  justify-content: center;
  padding: 0.2rem 0.2rem 0rem 0.2rem;
  margin-bottom: 0.2rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#newuser {
  display: flex;
  width: 50rem;
  height: 20rem;
  justify-content: center;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.2rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#newusermobile {
  display: flex;
  width: 90vw;
  height: 40vh;
  justify-content: center;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.2rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#newemail {
  display: flex;
  width: 50rem;
  height: 26rem;
  justify-content: center;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.2rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#newemailmobile {
  display: flex;
  width: 86vw;
  height: 28rem;
  justify-content: center;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 0.2rem;
  background-image: url(../ASSETS/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programHead {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programHeadMobile {
  display: flex;
  width: 95vw;
  height: auto;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programDay {
  display: flex;
  width: 95%;
  height: auto;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programDayShort {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programDayMobile {
  display: flex;
  width: 95vw;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programDayMobileShort {
  display: flex;
  width: 95vw;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programPay {
  display: flex;
  width: 95%;
  height: auto;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programPayMobile {
  display: flex;
  width: 95vw;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programSkunk {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#programSkunkMobile {
  display: flex;
  width: 95vw;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  background-image: url(../ASSETS/usercard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

#person {
  display: flex;
  width: 20rem;
  height: 20rem;
  left: 5rem;
  top: 5rem;
  background-image: url(../ASSETS/person.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: 0.5s;
}

#person:hover {
  background-image: url(../ASSETS/personHover.png);
  transition: 0.5s;
}

#personMobile {
  display: flex;
  width: 12rem;
  height: 12rem;
  left: 9rem;
  top: 2rem;
  background-image: url(../ASSETS/person.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#email {
  display: flex;
  width: 20rem;
  height: 20rem;
  left: 5rem;
  top: 5rem;
  background-image: url(../ASSETS/email.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: 0.5s;
}

#email:hover {
  background-image: url(../ASSETS/emailHover.png);
  transition: 0.5s;
}

#emailMobile {
  display: flex;
  width: 12rem;
  height: 12rem;
  left: 9rem;
  top: 2rem;
  background-image: url(../ASSETS/email.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}/*# sourceMappingURL=stylesheet.css.map */