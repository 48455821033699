@mixin program{

    #programHead{
        display: flex; width: 95%; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programHeadMobile{
        display: flex; width: 95vw; height: auto; flex-direction: row; justify-content: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programDay{
        display: flex; width: 95%; height: auto; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programDayShort{
        display: flex; width: 95%; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programDayMobile{
        display: flex; width: 95vw; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programDayMobileShort{
        display: flex; width: 95vw; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programPay{
        display: flex; width: 95%; height: auto; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programPayMobile{
        display: flex; width: 95vw; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programSkunk{ 
        display: flex; width: 95%; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #programSkunkMobile{
        display: flex; width: 95vw; flex-direction: row; justify-content: center;
        padding: .5rem 1rem .5rem 1rem;
        margin-bottom: 1rem;
        background-image: url(../ASSETS/usercard.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }


}