@mixin adminButton{

    #adminButton{
        display: flex; width: 20rem; height: 20rem;
        left: 5rem;
        top: 5rem;
        position: absolute;
        border-radius: 50%;
        
    }

    #adminButtonMobile{
        display: flex; width: 8rem; height: 8rem;
        left: 9rem;
        top: 2rem;
        position: absolute;
        border-radius: 50%;
        
    }



}