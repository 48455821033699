@mixin email{

    #email{
        display: flex; width: 20rem; height: 20rem;
        left: 5rem;
        top: 5rem;
        background-image: url(../ASSETS/email.png); 
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: .5s;
    }

    #email:hover{
        background-image: url(../ASSETS/emailHover.png); 
        transition: .5s;
    }

    #emailMobile{
        display: flex; width: 12rem; height: 12rem;
        left: 9rem;
        top: 2rem;
        background-image: url(../ASSETS/email.png); 
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }



}