@mixin container{

    .cont{
        display:flex; width: 100vw; height: 100vh;
        padding: .5rem .5rem .5rem .5rem;
        margin: 0rem 0rem 0rem 0rem;
        overflow: hidden;
    }

    .justify-left{ justify-content: flex-start; }
    .justify-center{ justify-content: center; }
    .justify-right{ justify-content: flex-end; }
    .justify-evenly{ justify-content: space-evenly; }
    .justify-space{ justify-content: space-between; }

    .align-top{ align-items: flex-start; }
    .align-center{ align-items: center; }
    .align-bottom{ align-items: flex-end; }

    .row{ flex-direction: row; }
    .column{ flex-direction: column; }

    .overflow-y{ overflow-y: auto; }
    .overflow-x{ overflow-x: auto; }
    .overflow-ynone{ overflow-y: hidden; }
    .overflow-xnone{ overflow-x: hidden; }
    .position-absolute{position: absolute;}
    .position-relative{position: relative;}
    .z-index-100{z-index: 100;}

    .font-50{ font-size: map-get($map: $font, $key: font-50 ); }
    .font-100{ font-size: map-get($map: $font, $key: font-100 ); }
    .font-150{ font-size: map-get($map: $font, $key: font-150 ); }
    .font-200{ font-size: map-get($map: $font, $key: font-200 ); }
    .font-300{ font-size: map-get($map: $font, $key: font-300 ); }
    .font-400{ font-size: map-get($map: $font, $key: font-400 ); }
    .font-500{ font-size: map-get($map: $font, $key: font-500 ); }
    .font-600{ font-size: map-get($map: $font, $key: font-600 ); }
    .font-700{ font-size: map-get($map: $font, $key: font-700 ); }
    .font-800{ font-size: map-get($map: $font, $key: font-800 ); }
    .font-900{ font-size: map-get($map: $font, $key: font-900 ); }

    .weight-100{ font-weight: 100; }
    .weight-200{ font-weight: 200; }
    .weight-300{ font-weight: 300; }
    .weight-400{ font-weight: 400; }
    .weight-500{ font-weight: 500; }
    .weight-600{ font-weight: 600; }
    .weight-700{ font-weight: 700; }
    .weight-800{ font-weight: 800; }
    .weight-900{ font-weight: 900; }

    .color-50{ color: map-get($map: $color, $key: color-50 ); }
    .color-100{ color: map-get($map: $color, $key: color-100 ); }
    .color-200{ color: map-get($map: $color, $key: color-200 ); }
    .color-300{ color: map-get($map: $color, $key: color-300 ); }
    .color-400{ color: map-get($map: $color, $key: color-400 ); }
    .color-500{ color: map-get($map: $color, $key: color-500 ); }
    .color-600{ color: map-get($map: $color, $key: color-600 ); }
    .color-700{ color: map-get($map: $color, $key: color-700 ); }
    .color-800{ color: map-get($map: $color, $key: color-800 ); }
    .color-900{ color: map-get($map: $color, $key: color-900 ); }
    .color-red{ color: red; }
    .color-baseWhite{ background-color: rgba(255, 255, 255, 0.7); }

    .text-center{text-align: center;};
    .text-left{text-align: left;}
    

    #back{
        background-image: url(../ASSETS/back.jpg); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    #backMobile{
        display: flex;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        background-image: url(../ASSETS/backm.jpg); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    #backImage{
        background-image: url(../ASSETS/backImage.jpg); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

}