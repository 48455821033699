@mixin arrowleft{

    #arrowleft{
        display: flex; width: 3rem; height: 3rem;
        margin: 0rem 1rem 10rem 0rem;
        background-image: url(../ASSETS/arrowleft.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #arrowleft:hover{
        background-image: url(../ASSETS/arrowlefthover.png); 
        transition: .5s;
    }

    #arrowNone{
        display: none;
    }

    #arrowleftMobile{
        display: flex; width: 4rem; height: 4rem;
        margin: 0rem 1rem 10rem 0rem;
        background-image: url(../ASSETS/arrowleft.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

}