@mixin arrowright{

    #arrowright{
        display: flex; width: 3rem; height: 3rem;
        margin: 0rem 0rem 10rem 1rem;
        background-image: url(../ASSETS/arrowright.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #arrowright:hover{
        background-image: url(../ASSETS/arrowrighthover.png); 
        transition: .5s;
    }

    #arrowrightMobile{
        display: flex; width: 4rem; height: 4rem;
        margin: 0rem 0rem 10rem 1rem;
        background-image: url(../ASSETS/arrowright.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

}