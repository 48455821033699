@mixin search{

    #search{
        display: flex; width: 50rem; height: 8rem; justify-content: center;
        padding: 1rem 1rem 1rem 1rem;
        margin-bottom: 2rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }

    #searchmobile{
        display: flex; width: 90vw; height: 15vh; justify-content: center;
        padding: .2rem .2rem 0rem .2rem;
        margin-bottom: .2rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: .5s;
    }



}