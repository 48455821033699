@mixin buttons{

    .button {
        box-shadow:inset 0px 1px 0px 0px map-get($map: $color, $key: color-100 );
        background:linear-gradient(to bottom, map-get($map: $color, $key: color-600 ) 5%, map-get($map: $color, $key: color-600 ) 100%);
        background-color:map-get($map: $color, $key: color-600 );
        border:1px solid map-get($map: $color, $key: color-800 );
        display:flex;
        justify-content: center;
        cursor:pointer;
        color:#ffffff;
        font-family:Arial;
        font-size:map-get($map: $font, $key: normal );
        font-weight:200;
        padding:6px 12px;
        text-decoration:none;
    }
    .button:hover {
        background:linear-gradient(to bottom, map-get($map: $color, $key: color-800 ) 5%, map-get($map: $color, $key: color-800 ) 100%);
        background-color:map-get($map: $color, $key: color-600 );
    }
    .button:active {
        position:relative;
        top:1px;
    }
}