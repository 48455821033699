@mixin pdf{

    #pdf{
        display:flex; width: 2vw; height: 4vh;
        margin: 0rem 0rem 0rem 4rem;
        background-image: url(../ASSETS/pdf.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: .4rem;
     }


}